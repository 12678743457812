<template>
  <v-card class="ma-2 pa-2">
    <v-row>
      <v-col cols="12" sm="5" lg="4" md="4">
        <div class="mb-3 mx-2">
          <v-card-title class="font-weight-black mt-0 pt-1">Chats</v-card-title>
          <v-card-text>
            <v-text-field append-icon="mdi-microphone" flat hide-details label="Search" prepend-inner-icon="mdi-magnify" solo-inverted></v-text-field>
          </v-card-text>
          <v-tabs v-model="tabs">
            <v-tab> Groups </v-tab>
            <v-tab> Contacts </v-tab>
          </v-tabs>
        </div>
        <v-card class="ml-2" v-if="tabs === 0" height="64%">
          <v-list two-line subheader v-if="listOfGroups.length">
            <v-list-item v-for="item in listOfGroups" :key="item.title" link @click="selectedContact = item._id">
              <v-list-item-avatar>
                <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-alert prominent outlined color="success" v-else>
            There are no chats
          </v-alert>
        </v-card>
        <v-card class="ml-2" v-if="tabs === 1" height="64%">
          <v-list two-line subheader v-if="listOfMyContacts.length">
            <v-list-item v-for="item in listOfMyContacts" :key="item.title" link @click="selectedContact = item._id">
              <v-list-item-avatar>
                <v-icon :class="[item.iconClass]"> {{ item.iconm ? item.icon : 'mdi-account'}} </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon> <v-icon color="grey lighten-1" @click="removeFromContacts(item._id)"> mdi-account-minus </v-icon> </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-alert prominent outlined color="success" v-else>
            There are no chats
          </v-alert>
        </v-card>
      </v-col>
      <v-col cols="12" sm="7" lg="8" md="8" >
        <v-card outlined>
          <v-card-text class="pb-1">
            <section class="chat-area">
              <p v-for="(message, index) in messages" :key="index" class="message" :class="{ 'message-out': message.author === 'you', 'message-in': message.author !== 'you' }">
                {{ message.body }}
              </p>
            </section>
          </v-card-text>
          <v-card-actions class="pt-0 my-0">
            <v-row class="d-flex justify-center">
              <v-col cols="8" lg="8" md="9" sm="8">
                <v-text-field hide-details rounded outlined label="Enter Message Here" v-model="message" @keypress.enter="sendMsg"></v-text-field>
              </v-col>
              <v-col cols="4" lg="2" md="3" sm="4" class="mt-3">
                <v-btn color="primary" @click="sendMsg"><v-icon>mdi-send</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div class="py-lg-6 px-8" style="position: fixed; bottom: 0; right: 0; z-index: 3;">
      <v-btn class="primary white--text" elevation="5" x-large icon outlined @click="openDialog">
        <v-icon size="22"> mdi-plus </v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="contactDialog" max-width="500px">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title> Add Contact </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5">
            <v-text-field outlined dense hide-details label="Search here" prepend-inner-icon="mdi-magnify" v-model="contactSearch" clearable class="pt-4"></v-text-field>
            <v-list two-line subheader v-if="groupMembersItems.length">
              <v-list-item v-for="(item, index) in groupMembersItems" :key="item._id" link>
                <v-list-item-avatar>
                  <v-icon :class="[item.iconClass]"> {{ item.profile ? item.profile : 'mdi-account' }} </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon v-if="!item.isAlreadyFriend"> <v-icon color="grey lighten-1" @click="addToContact(item._id, index)"> mdi-account-plus </v-icon> </v-btn>
                  <v-btn icon v-if="item.isAlreadyFriend"> <v-icon color="primary lighten-1" > mdi-account </v-icon> </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-card-text v-else class="title pt-10">
                {{ !contactSearch ? 'Search Here' : 'No User Found' }}
            </v-card-text>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="500px">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title> Create Group </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-5">
            <v-text-field label="Group Name" v-model="groupObj.name" :rules="setValidation('requiredValidation')" class="pt-5" hide-details outlined dense></v-text-field>
            <v-textarea label="Group Info" v-model="groupObj.info" dense outlined class="pt-5"></v-textarea>
            <v-autocomplete outlined dense hide-details label="Notify To" v-model="groupObj.members" chips clearable
            deletable-chips multiple :items="groupMembersItems" :search-input.sync="contactSearch"
            item-text="email" item-value="_id" small-chips no-data-text="Start typing to search" class="pt-4"></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="createGroup" :disabled="!valid"> Add </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
      </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  data () {
      return {
        messages: [
          {
            body: 'Welcome to the chat, I\'m Bob!',
            author: 'bob'
          },
          {
            body: 'Thank you Bob',
            author: 'you'
          },
          {
            body: 'You\'re most welcome',
            author: 'bob'
          }
        ],
        valid: false,
        dialog: false,
        contactDialog: false,
        tabs: null,
        loading: false,
        notifyToSearch: '',
        groupMembersItems: [],
        selectedContact: null,
        personalChats: {},
        message: '',
        listOfMyContacts: [
          // { icon: 'mdi-folder', iconClass: 'grey lighten-1 white--text', title: 'Photos', subtitle: 'Jan 9, 2014' },
          // { icon: 'mdi-folder', iconClass: 'grey lighten-1 white--text', title: 'Recipes', subtitle: 'Jan 17, 2014' },
          // { icon: 'mdi-folder', iconClass: 'grey lighten-1 white--text', title: 'Work', subtitle: 'Jan 28, 2014' },
        ],
        listOfGroups: [],
        groupObj: {},
        contactSearch: ''
      }
    },
    mounted () {
      this.getListofGroups()
      this.getListOfMyContacts()
      // this.$eventBus.$on('asas', (msg) => {
      //   let text = msg.sender === this.activeUserId ? 'Sent' : 'Received'
      //   // console.log(msg, text)
      //   this.messages.push(msg)
      // })
    },
    watch: {
      contactSearch (value) {
        if (value) {
          let query = [{
              $match: {
                $and: [
                  { name: { $regex: value.toLowerCase().trim() } },
                  { email: { $regex: value.toLowerCase().trim() } },
                ]
              }
            }]
          this.$_execute('post', `users/query`, query).then(response => {
            if (response.data) this.groupMembersItems = response.data
          })
          // let myContacts = []
          // this.listOfMyContacts.forEach(x => myContacts.push(x._id))
          // this.$_execute('get', `account/query/${ value }`).then(response => {
          //   response.data.map(x => x.isAlreadyFriend = myContacts.includes(x._id) ? true : false )
          //   if (response.data) this.groupMembersItems = response.data
          // })
        } else this.groupMembersItems = []
      }
    },
    methods: {
      openDialog () {
        this.tabs === 1 ? this.contactDialog = !this.contactDialog : this.dialog = !this.dialog
      },
      createGroup () {
        let payload = this.$_copy(this.groupObj)
        this.$_execute('post', 'groups', payload).then(() => {
          this.dialog = false
          this.groupObj = {}
          this.getListofGroups()
        })
      },
      getListofGroups () {
        this.$_execute('get', 'groups').then(response => {
          this.listOfGroups = response.data
        })
      },
      getListOfMyContacts () {
        let payload = { skip: 0,  }
        this.$_execute('post', 'users/contacts/getContacts', payload).then(response => {
          this.listOfMyContacts = response.data.contacts
        })
      },
      addToContact(id, index) {
        let contacts = [id]
        this.$_execute('post', 'users/contacts/add', contacts).then(response => {
          this.groupMembersItems[index].isAlreadyFriend = true
          if (response) this.getListOfMyContacts()
        })
      },
      removeFromContacts (id) {
        let contacts = [id]
        this.$_execute('post', 'users/contacts/remove', contacts).then(response => {
          if (response) this.getListOfMyContacts()
        })
      },
      sendMsg () {
        this.$socket.send('privateMsg', {
          tenant: this.tenant,
          from: this.userDetails._id,
          to: this.selectedContact,
          message: this.message,
          dateTime: moment()
        })
        this.message = ''
      }
    }
    // to delete a group
    // this.$_execute('delete', `${ this.tenant }/groups/609aaa8799c1be2710d2af3c`)
}
</script>
<style scoped>
.chat-area {
  background: white;
  height: 60vh;
  padding: 1em;
  overflow: auto;
  margin: 0 auto 2em auto;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.3)
}
.message {
  width: 45%;
  padding-right: 50px;
  border-radius: 10px;
  padding: .5em;
  font-size: .8em;
}
.message-out {
  background: #407FFF;
  color: white;
  margin-left: 50%;
}
.message-in {
  background: #F1F0F0;
  color: black;
}

</style>
